const Arrow = ({
	direction = "down",
	fill = "none",
	stroke = "none"
}) => {
	if (direction === "down") {
		return (
			<svg className="arrow down" viewBox="0 0 61 44" fill={fill} stroke={stroke} xmlns="http://www.w3.org/2000/svg">
				<path d="M57.6502 31.2263C66.7634 6.64329 48.0607 -1.64026 36.2777 1.71037C27.2008 4.29148 20.0975 11.4551 26.5403 19.3432C28.084 21.2332 31.8568 23.3539 33.8229 22.2948C36.9331 20.6193 36.8138 16.8727 35.9139 13.6062C34.2791 7.67241 29.3949 4.976 22.79 6.84341C12.4539 9.76577 7.31679 20.0282 6.26821 29.888C6.05934 31.852 7.02336 46.6114 9.19192 38.5871C9.58549 37.1308 10.5884 32.7273 10.1021 34.1565C9.29587 36.5262 8.94369 37.8905 8.94369 40.4512C8.94369 40.9596 9.32309 43.6165 8.17139 42.6936C5.81325 40.8038 3.31753 39.101 1 37.1553" pathLength="1" strokeLinecap="round"/>
			</svg>
		)
	}
}

export default Arrow;